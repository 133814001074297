.awsui_root_2qdw9_13svb_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:var(--font-body-m-size-3mVn35, 14px);
  line-height:22px;
  line-height:var(--font-body-m-line-height-3H4gFo, 22px);
  color:#16191f;
  color:var(--color-text-body-default-fgLpHn, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-family-base-SgSfB7, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  cursor:inherit;
  display:flex;
  width:100%;
  flex-wrap:wrap;
  justify-content:space-between;
}
.awsui_root-no-actions_2qdw9_13svb_17.awsui_root-variant-h2_2qdw9_13svb_17:not(#\9), .awsui_root-no-actions_2qdw9_13svb_17.awsui_root-variant-h3_2qdw9_13svb_17:not(#\9), .awsui_root-has-description_2qdw9_13svb_17.awsui_root-variant-h2_2qdw9_13svb_17:not(#\9), .awsui_root-has-description_2qdw9_13svb_17.awsui_root-variant-h3_2qdw9_13svb_17:not(#\9){
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-1P8vQQ, 4px);
}

.awsui_main_2qdw9_13svb_21:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
}
:not(#\9):not(.awsui_root-no-actions_2qdw9_13svb_17) > .awsui_main_2qdw9_13svb_21{
  margin-right:8px;
  margin-right:var(--space-xs-2P4jwl, 8px);
}
.awsui_main-variant-h1_2qdw9_13svb_29:not(#\9){
  padding:4px 0;
  padding:var(--space-scaled-2x-xxs-93QkgF, 4px) 0;
}
.awsui_main-variant-h2_2qdw9_13svb_32:not(#\9), .awsui_main-variant-h3_2qdw9_13svb_32:not(#\9){
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-1P8vQQ, 4px);
}
:not(#\9):not(.awsui_root-has-description_2qdw9_13svb_17) > .awsui_main-variant-h2_2qdw9_13svb_32, :not(#\9):not(.awsui_root-has-description_2qdw9_13svb_17) > .awsui_main-variant-h3_2qdw9_13svb_32{
  padding-bottom:2px;
  padding-bottom:var(--space-xxxs-10CN52, 2px);
}

.awsui_actions_2qdw9_13svb_39:not(#\9){
  display:flex;
  align-items:flex-start;
}
.awsui_actions-variant-h1_2qdw9_13svb_43:not(#\9){
  padding:8px 0;
  padding:var(--space-scaled-xs-m2APzp, 8px) 0;
}
.awsui_actions-variant-h2_2qdw9_13svb_46:not(#\9), .awsui_actions-variant-h3_2qdw9_13svb_46:not(#\9){
  padding:4px 0;
  padding:var(--space-scaled-xxs-1P8vQQ, 4px) 0;
}
.awsui_root-has-description_2qdw9_13svb_17 > .awsui_actions-variant-h2_2qdw9_13svb_46:not(#\9), .awsui_root-has-description_2qdw9_13svb_17 > .awsui_actions-variant-h3_2qdw9_13svb_46:not(#\9){
  padding-bottom:0;
}

.awsui_title_2qdw9_13svb_53:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  color:#16191f;
  color:var(--color-text-heading-default-267rhj, #16191f);
}
.awsui_title-variant-h2_2qdw9_13svb_59:not(#\9), .awsui_title-variant-h3_2qdw9_13svb_59:not(#\9){
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-1P8vQQ, 4px);
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-1P8vQQ, 4px);
}
.awsui_title-variant-h2_2qdw9_13svb_59:not(#\9){
  font-size:18px;
  font-size:var(--font-heading-l-size-1Vsn3y, 18px);
  line-height:22px;
  line-height:var(--font-heading-l-line-height-1_zauO, 22px);
  letter-spacing:normal;
  letter-spacing:var(--font-heading-l-letter-spacing-3odnzg, normal);
  font-weight:700;
  font-weight:var(--font-heading-l-weight-uf_Q3V, 700);
}
.awsui_title-variant-h3_2qdw9_13svb_59:not(#\9){
  font-size:18px;
  font-size:var(--font-heading-m-size-2002aW, 18px);
  line-height:22px;
  line-height:var(--font-heading-m-line-height-2Q-DLd, 22px);
  letter-spacing:normal;
  letter-spacing:var(--font-heading-m-letter-spacing-1r2EWV, normal);
  font-weight:400;
  font-weight:var(--font-heading-m-weight-3s7Qeu, 400);
}

.awsui_info_2qdw9_13svb_76:not(#\9){
  padding-right:12px;
  padding-right:var(--space-s-3g7GuV, 12px);
}

.awsui_description_2qdw9_13svb_80:not(#\9){
  margin:0;
  padding:0;
  color:#545b64;
  color:var(--color-text-heading-secondary-3NRPuq, #545b64);
}
.awsui_description-variant-h1_2qdw9_13svb_85:not(#\9){
  font-size:14px;
  font-size:var(--font-body-m-size-3mVn35, 14px);
  line-height:22px;
  line-height:var(--font-body-m-line-height-3H4gFo, 22px);
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-1P8vQQ, 4px);
}
.awsui_description-variant-h2_2qdw9_13svb_90:not(#\9){
  font-size:12px;
  font-size:var(--font-header-h2-description-size-3YSnco, 12px);
  line-height:16px;
  line-height:var(--font-header-h2-description-line-height-1teOsO, 16px);
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-1P8vQQ, 4px);
}
.awsui_description-variant-h3_2qdw9_13svb_95:not(#\9){
  font-size:12px;
  font-size:var(--font-body-s-size-26MKNB, 12px);
  line-height:16px;
  line-height:var(--font-body-s-line-height-1u2UBI, 16px);
  letter-spacing:normal;
  letter-spacing:var(--font-body-s-letter-spacing-svMjV2, normal);
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-1P8vQQ, 4px);
}

.awsui_heading_2qdw9_13svb_102:not(#\9){
  margin:0;
  display:inline;
  font-size:inherit;
  margin-right:8px;
  margin-right:var(--space-xs-2P4jwl, 8px);
}
.awsui_heading_2qdw9_13svb_102:not(#\9):only-child{
  margin:0;
}
.awsui_heading-variant-h1_2qdw9_13svb_111:not(#\9){
  font-size:28px;
  font-size:var(--font-heading-xl-size-3bhSlp, 28px);
  line-height:36px;
  line-height:var(--font-heading-xl-line-height-2ofyvs, 36px);
  letter-spacing:normal;
  letter-spacing:var(--font-heading-xl-letter-spacing-1VjHvF, normal);
  font-weight:400;
  font-weight:var(--font-heading-xl-weight-3WIn94, 400);
}
.awsui_heading-variant-h2_2qdw9_13svb_117:not(#\9){
  font-weight:700;
  font-weight:var(--font-heading-l-weight-uf_Q3V, 700);
}
.awsui_heading-variant-h3_2qdw9_13svb_120:not(#\9){
  font-weight:400;
  font-weight:var(--font-heading-m-weight-3s7Qeu, 400);
}

.awsui_counter_2qdw9_13svb_128:not(#\9){
  color:#687078;
  color:var(--color-text-counter-Qnq0Ij, #687078);
  font-weight:400;
}